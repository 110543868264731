
/*.dark .el-menu--horizontal {*/
/*  background-color: #F2F3F5 !important;*/
/*}*/
.dark .el-menu--horizontal .el-menu {
  background-color: #F2F3F5;
}
.el-menu--horizontal .el-menu--popup{
  -webkit-box-shadow: unset;
          box-shadow: unset;
}
.el-popover .el-button--text {
  color: #333333 !important;
}
/*.el-select-dropdown {*/
/*  z-index: 2025 !important;*/
/*}*/
/*.el-message {*/
/*  z-index: 2025 !important;*/
/*}*/
/*.el-picker-panel {*/
/*  z-index: 2025 !important;*/
/*}*/
/*.el-message-box__wrapper {*/
/*  z-index: 2025 !important;*/
/*}*/
/*.v-modal {*/
/*  z-index: 2024 !important;*/
/*}*/
